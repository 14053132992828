import Dashboard from "@material-ui/icons/Dashboard";
import {
  Commute as CommuteIcon,
  Room as RoomIcon,
  DirectionsBus as DirectionsBusIcon,
  DirectionsWalk as DirectionsWalkIcon,
  Business as BusinessIcon,
  HomeWork as HomeWorkIcon,
  EmojiObjects as EmojiObjectIcon,
  ViewCarousel as ViewCarouselIcon,
  Event as EventIcon,
  Category as CategoryIcon,
  Schedule as ScheduleIcon,
  Http as HttpIcon,
  LocalOffer as LocalOfferIcon,
  SportsEsports as SportsEsportsIcon,
  Kitchen as KitchenIcon,
  Computer as ComputerIcon,
  LiveTv as LiveTvIcon,
  Help as HelpIcon,
  Assignment as AssignmentIcon,
  Person as PersonIcon,
  LocalShipping as LocalShippingIcon,
  Settings as SettingsIcon,
  Notifications as NotificationIcon,
} from "@material-ui/icons";

import DashboardPage from "views/Dashboard/Dashboard.js";

import AutoStand from "views/AutoStand/AutoStand.js";
import AutoRikshaw from "views/AutoRikshaw/AutoRikshaw.js";

import WorkCategory from "views/WorkCategory/WorkCategory";
import Worker from "views/Worker/Worker";

import RepresentativeCategory from "views/RepresentativeCategory/RepresentativeCategory";
import Representative from "views/Representative/Representative";

import BusinessCategory from "views/BusinessCategory/BusinessCategory";
import Business from "views/Business/Business";

import EnterpriseCategory from "views/EnterpriseCategory/EnterpriseCategory";
import Enterprise from "views/Enterprise/Enterprise";

import BusRoute from "views/BusRoute/BusRoute";
import Bus from "views/Bus/Bus";

import EmergencyCategory from "views/EmergencyCategory/EmergencyCategory";
import Emergency from "views/Emergency/Emergency";

import VehicleCategory from "views/VehicleCategory/VehicleCategory";
import Vehicle from "views/Vehicle/Vehicle";

import EventCategory from "views/EventCategory/EventCategory";
import Event from "views/Event/Event";
import EventShedule from "views/EventShedule/EventShedule";

import Channel from "views/Channel/Channel";
import Tool from "views/Tool/Tool";
import Cooking from "views/Cooking/Cooking";
import Game from "views/Game/Game";

import Carousel from "views/Carousel/Carousel";

import OnlineServiceCategory from "views/OnlineServiceCategory/OnlineServiceCategory";
import OnlineService from "views/OnlineService/OnlineService";

import OfferCategory from "views/OfferCategory/OfferCategory";
import Offer from "views/Offer/Offer";

import Help from "views/Help/Help";
import Terms from "views/Terms/Terms";

import NotificationCategory from "views/NotificationCategory/NotificationCategory";
import Notification from "views/Notification/Notification";

import Settings from "views/Settings/Settings";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",

    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/business-category",
    name: "Business Categories",
    icon: CategoryIcon,
    component: BusinessCategory,
    layout: "/admin",
  },
  {
    path: "/businesses",
    name: "Businesses",

    icon: BusinessIcon,
    component: Business,
    layout: "/admin",
  },
  // {
  //   path: "/enterprise-category",
  //   name: "Enterprise Categories",
  //   icon: CategoryIcon,
  //   component: EnterpriseCategory,
  //   layout: "/admin",
  // },
  {
    path: "/enterprises",
    name: "Enterprises",

    icon: HomeWorkIcon,
    component: Enterprise,
    layout: "/admin",
  },
  {
    path: "/auto-stand",
    name: "Auto stands",

    icon: CategoryIcon,
    component: AutoStand,
    layout: "/admin",
  },
  {
    path: "/auto-rikshaw",
    name: "Auto rikshaws",

    icon: CommuteIcon,
    component: AutoRikshaw,
    layout: "/admin",
  },
  {
    path: "/bus-route",
    name: "Bus Routes",
    icon: RoomIcon,
    component: BusRoute,
    layout: "/admin",
    toggle: "bus",
  },
  {
    path: "/bus-timing",
    name: "Bus Timings",
    icon: DirectionsBusIcon,
    component: Bus,
    layout: "/admin",
  },
  {
    path: "/work-category",
    name: "Work Categories",
    icon: CategoryIcon,
    component: WorkCategory,
    layout: "/admin",
  },
  {
    path: "/workers",
    name: "Workers",
    icon: DirectionsWalkIcon,
    component: Worker,
    layout: "/admin",
  },

  {
    path: "/emergency-category",
    name: "Emergency categories",
    icon: CategoryIcon,
    component: EmergencyCategory,
    layout: "/admin",
  },
  {
    path: "/emergencies",
    name: "Emergencies",
    icon: EmojiObjectIcon,
    component: Emergency,
    layout: "/admin",
  },

  {
    path: "/vehicle-category",
    name: "Vehicle categories",
    icon: CategoryIcon,
    component: VehicleCategory,
    layout: "/admin",
  },
  {
    path: "/vehicles",
    name: "Vehicles",
    icon: LocalShippingIcon,
    component: Vehicle,
    layout: "/admin",
  },

  {
    path: "/representative-categories",
    name: "Representative Categories",
    icon: CategoryIcon,
    component: RepresentativeCategory,
    layout: "/admin",
  },

  {
    path: "/representatives",
    name: "Representative",
    icon: PersonIcon,
    component: Representative,
    layout: "/admin",
  },

  {
    path: "/event-categories",
    name: "Event categories",
    icon: CategoryIcon,
    component: EventCategory,
    layout: "/admin",
  },

  {
    path: "/events",
    name: "Events",
    icon: EventIcon,
    component: Event,
    layout: "/admin",
  },

  {
    path: "/event-shedules",
    name: "Event Schedules",
    icon: ScheduleIcon,
    component: EventShedule,
    layout: "/admin",
  },

  {
    path: "/carousel",
    name: "Carousel",
    icon: ViewCarouselIcon,
    component: Carousel,
    layout: "/admin",
  },

  {
    path: "/online-service-categories",
    name: "Online service categories",
    icon: CategoryIcon,
    component: OnlineServiceCategory,
    layout: "/admin",
  },
  {
    path: "/online-services",
    name: "Online services",
    icon: HttpIcon,
    component: OnlineService,
    layout: "/admin",
  },
  {
    path: "/offer-categories",
    name: "Offer categories",
    icon: CategoryIcon,
    component: OfferCategory,
    layout: "/admin",
  },
  {
    path: "/offers",
    name: "Offers",
    icon: LocalOfferIcon,
    component: Offer,
    layout: "/admin",
  },
  {
    path: "/channels",
    name: "Channels",
    icon: LiveTvIcon,
    component: Channel,
    layout: "/admin",
  },
  {
    path: "/tools",
    name: "Tools",
    icon: ComputerIcon,
    component: Tool,
    layout: "/admin",
  },
  {
    path: "/cookings",
    name: "Cookings",
    icon: KitchenIcon,
    component: Cooking,
    layout: "/admin",
  },
  {
    path: "/games",
    name: "Games",
    icon: SportsEsportsIcon,
    component: Game,
    layout: "/admin",
  },
  {
    path: "/help",
    name: "Help",
    icon: HelpIcon,
    component: Help,
    layout: "/admin",
  },

  {
    path: "/terms",
    name: "Terms",
    icon: AssignmentIcon,
    component: Terms,
    layout: "/admin",
  },
  {
    path: "/notification-categories",
    name: "Notification categories",
    icon: CategoryIcon,
    component: NotificationCategory,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notification",
    icon: NotificationIcon,
    component: Notification,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: SettingsIcon,
    component: Settings,
    layout: "/admin",
  },
];

export default dashboardRoutes;
