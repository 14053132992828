import axios from "axios";
import { apiUrl } from "../config";

let verifyToken = async (token) => {
  try {
    let response = await axios.post(
      `${apiUrl}`,
      {},
      { "x-access-token": token }
    );
    if (response && response.data && response.data.status == 200) {
      return true;
    }
  } catch (err) {
    return false;
  }
};

export default verifyToken;
