let apiUrl;
let apiUrlRoot;
if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
  console.log("Production backend");
  apiUrl = "https://api-parambath.onnich.com/admin";
  apiUrlRoot = "https://api-parambath.onnich.com";
} else {
  console.log("Development backend");
  // apiUrl = "https://api-parambath.onnich.com/admin";
  apiUrl = "http://localhost:3070/admin";
  apiUrlRoot = "http://localhost:3070";
}

let cloudinaryCredentials;
if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
  cloudinaryCredentials = {
    URL: "https://api.cloudinary.com/v1_1/lensbird/image/upload",
    cloudinaryApiUrl: "https://res.cloudinary.com/lensbird",
    UPLOAD_PRESET: "parambath",
    ClOUD_NAME: "parambath",
    UPLOAD_URL: "https://api.cloudinary.com/v1_1/lensbird/image/upload",
  };
} else {
  cloudinaryCredentials = {
    URL: "https://api.cloudinary.com/v1_1/lensbird/image/upload",
    cloudinaryApiUrl: "https://res.cloudinary.com/lensbird",
    UPLOAD_PRESET: "parambath-test",
    ClOUD_NAME: "parambath-test",
    UPLOAD_URL: "https://api.cloudinary.com/v1_1/lensbird/image/upload",
  };
}

export { apiUrl, apiUrlRoot, cloudinaryCredentials };
