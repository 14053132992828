import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
// core components
import Admin from "./layouts/Admin.js";
import RTL from "./layouts/RTL.js";
import Login from "./views/Auth/Login";
import Home from "./views/Home/Home";

import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";

import "assets/css/material-dashboard-react.css?v=1.9.0";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/admin" component={Admin} />
      <Route path="/rtl" component={RTL} />
      <Route path="/login" exact component={Login} />
      <Route path="/" exact component={Home} />
      <Route
        path="/download"
        component={() => {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.onnich.parambath";
          return null;
        }}
      />

      <Route path="/privacy-policy" exact component={PrivacyPolicy} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
