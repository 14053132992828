import request from "superagent";
import { cloudinaryCredentials } from "../config";

let cloudinaryService = (image, options) => {
  let imageName = image[0].name.split(".").slice(0, -1).join(".");
  let unixTime = Math.round(new Date().getTime() / 1000);
  let randomNumber = Math.floor(Math.random() * (+100000 - +10000)) + +10000;
  let upload = request
    .post(cloudinaryCredentials.URL)
    .field("upload_preset", cloudinaryCredentials.UPLOAD_PRESET)
    .field("file", image)
    .field(
      "public_id",
      `${options.directory}/${imageName}_${unixTime}_${randomNumber}`
    );
  return upload
    .on("progress", (response) => {
      console.log(response);
    })
    .then((response) => {
      if (response.body.secure_url !== "") return response;
    });
};

export default cloudinaryService;
