import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import {
  CircularProgress,
  TableRow,
  TableCell,
  Badge,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import AddIcon from "@material-ui/icons/Add";
import AddEdit from "./AddEdit";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
} from "@material-ui/core";
import Draggable from "react-draggable";
import axios from "axios";
import { apiUrl } from "../../config";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  premiumBadge: {
    backgroundColor: "#fdd835",
  },
  normalBadge: {
    backgroundColor: "#757575",
  },
  editButton: {
    cursor: "pointer",
    "&:hover": {
      color: "#8a8989",
    },
  },
  deleteButton: {
    cursor: "pointer",
    "&:hover": {
      color: "#e23434",
    },
  },
  addIcon: {
    cursor: "pointer",
    "&:hover": {
      color: "#b1b0b0",
    },
  },
};

let token = localStorage.getItem("token");
const useStyles = makeStyles(styles);

export default function TableList() {
  const [modalOpen, setModalOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState("");
  const [businessCategories, setBusinessCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editedRow, setEditedRow] = useState("");
  const [itemToRemove, setItemToRemove] = useState("");
  const [dialogStatus, setDialogStatus] = useState(false);

  useEffect(() => {
    fetchAutos();
  }, []);

  let fetchAutos = async () => {
    try {
      let response = await axios.get(`${apiUrl}/business-category/list?`, {
        headers: {
          "x-access-token": token,
        },
      });
      if (response && response.data && response.data.status == 200) {
        setBusinessCategories(response.data.data);
      } else {
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  let updateList = (data) => {
    if (itemToEdit) {
      businessCategories.map((businessCategory, index) => {
        if (businessCategory._id === itemToEdit._id) {
          businessCategories[index] = data;
          setEditedRow(index);
        }
      });
      setBusinessCategories(businessCategories);
      setItemToEdit("");
    } else {
      businessCategories.unshift(data);
      setBusinessCategories(businessCategories);
    }
  };

  let openEditModal = (item) => {
    setItemToEdit(item);
    setModalOpen(true);
  };

  const classes = useStyles();

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  let openDialog = (item) => {
    setItemToRemove(item);
    setDialogStatus(true);
  };

  let closeDialog = () => {
    setItemToRemove("");
    setDialogStatus(false);
  };

  let removeItem = async () => {
    try {
      let response = await axios.delete(
        `${apiUrl}/business-category/${itemToRemove._id}`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      if (response && response.data && response.data.status == 200) {
        businessCategories.map((businessCategory, index) => {
          if (businessCategory._id === response.data.data._id) {
            businessCategories.splice(index, 1);
          }
        });
        setItemToRemove("");
        setBusinessCategories(businessCategories);
        setDialogStatus(false);
      } else {
      }
    } catch (err) {}
  };

  return (
    <div>
      <AddEdit
        data={itemToEdit}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        updateList={updateList}
        setItemToEdit={setItemToEdit}
      />
      <Dialog
        open={dialogStatus}
        onClose={closeDialog}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent>
          <DialogContentText>Do you want to remove the item?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={removeItem} color="primary">
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader
              color="primary"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <h4 className={classes.cardTitleWhite}>Business Categories</h4>
                {/* <p className={classes.cardCategoryWhite}>
                  Auto riskshaw details
                </p> */}
              </div>
              <div>
                <AddIcon
                  onClick={() => setModalOpen(true)}
                  className={classes.addIcon}
                />{" "}
              </div>
            </CardHeader>
            <CardBody>
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <Table
                  tableHeaderColor="primary"
                  tableHead={["Icon", "Name", "Malayalam Name"]}
                  tableBody={businessCategories.map((item, index) => {
                    return (
                      <TableRow
                        style={
                          editedRow === index ? { background: "#eaeaea" } : null
                        }
                      >
                        <TableCell>
                          {item.icon ? (
                            <img
                              src={item.icon}
                              style={{
                                maxWidth: "50px",
                                height: "businessCategory",
                                maxHeight: "50px",
                              }}
                            />
                          ) : null}
                        </TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.malayalamName}</TableCell>

                        <TableCell>
                          <div
                            onClick={() => openEditModal(item)}
                            className={classes.editButton}
                          >
                            Edit
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            onClick={() => openDialog(item)}
                            className={classes.deleteButton}
                          >
                            Delete
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
