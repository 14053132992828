/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <p
          style={{
            paddingBottom: "2rem",
            fontSize: ".9em",
            textAlign: "center",
          }}
        >
          Made with Love in God's own country | Powered by&nbsp;
          <a href="https://onnich.com" target="_blank">
            Onnich
          </a>
          <br />
          Copyright ©&nbsp;
          <a href="https://floyet.com" target="_blank">
            Floyet Technologies LLP
          </a>
          &nbsp;2021
        </p>
      </div>
    </footer>
  );
}
