import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from "axios";
import { apiUrl } from "../../config";
import { Dialog, DialogActions } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import ImageUploader from "react-images-upload";
import cloudinaryService from "../../lib/cloudinaryService";
import { Close } from "@material-ui/icons";
import Loader from "react-loader-spinner";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  removeTextContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    background: "#d8d8d8",
    borderRadius: ".3rem",
    cursor: "pointer",
    "&:hover": {
      background: "#565555",
    },
  },
  removeText: {
    color: "white",
    fontSize: "2rem",
  },
};

let token = localStorage.getItem("token");
const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();

  const [settings, setSettings] = useState({
    _id: "",
    name: "",
    about: "",
    address: "",
    phoneNumber: "",
    phoneNumber2: "",
    email: "",
    whatsapp: "",
    facebook: "",
    instagram: "",
    youtube: "",
    shareUrlMessage: "",
    appUrlAndroid: "",
    appUrlIos: "",
    images: [],
  });
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formSubmitMessage, setFormSubmitMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [imageDropped, setImageDropped] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [indexOfImageToRemove, setIndexOfImageToRemove] = useState("");

  useEffect(() => {
    fetchSettings();
  }, []);

  let fetchSettings = async () => {
    try {
      let response = await axios.get(`${apiUrl}/settings`, {
        headers: {
          "x-access-token": token,
        },
      });
      if (response && response.data && response.data.status == 200) {
        setSettings(response.data.data);
      } else {
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  let handleChange = (e) => {
    let value = e.target.value;
    let newSettingsData = { ...settings, [e.target.name]: value };
    setSettings(newSettingsData);
  };

  let handleSubmit = async (e) => {
    setFormSubmitMessage({});
    setFormSubmitting(true);
    try {
      e.preventDefault();
      let body = settings;
      let response = await axios.put(`${apiUrl}/settings`, body, {
        headers: {
          "x-access-token": token,
        },
      });
      if (response && response.data && response.data.status == 200) {
        setFormSubmitMessage({
          message: "Settings updated successfully",
          type: "success",
        });
      } else {
        setFormSubmitMessage({
          message: "Error occured",
          type: "error",
        });
      }
      setFormSubmitting(false);
    } catch (err) {
      setFormSubmitMessage({
        message: err.response.data.message,
        type: "error",
      });
      setFormSubmitting(false);
    }
  };

  let handleOndrop = async (image) => {
    setImageDropped(true);
    let cloudinaryOptions = {
      directory: "settings",
    };
    let cloudinaryResponse = await cloudinaryService(image, cloudinaryOptions);
    setSettings({
      ...settings,
      images: [...settings.images, cloudinaryResponse.body.secure_url],
    });
    setImageDropped(false);
  };

  let openDialog = (index) => {
    setIndexOfImageToRemove(index);
    setDialogStatus(true);
  };

  let closeDialog = () => {
    setIndexOfImageToRemove("");
    setDialogStatus(false);
  };

  let removeImage = () => {
    settings.images.splice(indexOfImageToRemove, 1);
    setSettings({
      ...settings,
      images: settings.images,
    });
    setIndexOfImageToRemove("");
    setDialogStatus(false);
  };

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  return (
    <div>
      <Dialog
        open={dialogStatus}
        onClose={closeDialog}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            Do you want to remove the image?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={removeImage} color="primary">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <form onSubmit={handleSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Settings</h4>
                  {/* <p className={classes.cardCategoryWhite}>Complete your profile</p> */}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "name",
                          value: settings.name,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Phone number"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "phoneNumber",
                          value: settings.phoneNumber,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Phone number 2"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "phoneNumber2",
                          value: settings.phoneNumber2,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "email",
                          value: settings.email,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Whatsapp"
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "whatsapp",
                          value: settings.whatsapp,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Website"
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "website",
                          value: settings.website,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Facebook"
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "facebook",
                          value: settings.facebook,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Instagram"
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "instagram",
                          value: settings.instagram,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Youtube"
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "youtube",
                          value: settings.youtube,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Help video"
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "helpVideo",
                          value: settings.helpVideo,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Products"
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "products",
                          value: settings.products,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Technology"
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "tech",
                          value: settings.tech,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Coding"
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "coding",
                          value: settings.coding,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Share URL Message"
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "shareUrlMessage",
                          value: settings.shareUrlMessage,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="App URL Android"
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "appUrlAndroid",
                          value: settings.appUrlAndroid,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="App URL iOS"
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "appUrlIos",
                          value: settings.appUrlIos,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          multiline: true,
                          line: 5,
                          disabled: false,
                          name: "address",
                          value: settings.address,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="About section"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          multiline: true,
                          line: 5,
                          disabled: false,
                          name: "about",
                          value: settings.about,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem
                      container
                      direction="row"
                      justify="left"
                      alignItems="left"
                    >
                      {settings.images &&
                        settings.images.length !== 0 &&
                        settings.images.map((image, index) => {
                          return (
                            <GridItem xs={6} sm={6} md={4} md={3}>
                              <div className={classes.imageContainer}>
                                <img src={image} className={classes.image} />
                                <div className={classes.removeTextContainer}>
                                  <div
                                    className={classes.removeText}
                                    onClick={() => openDialog(index)}
                                  >
                                    <Close />
                                  </div>
                                </div>
                              </div>
                            </GridItem>
                          );
                        })}
                      <GridItem xs={6} sm={6} md={4} md={3}>
                        {imageDropped ? (
                          <Loader
                            type="ThreeDots"
                            color="#87CB16"
                            height={30}
                            width={30}
                          />
                        ) : (
                          <ImageUploader
                            withIcon={true}
                            withLabel={false}
                            buttonText="Upload image"
                            onChange={handleOndrop}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".gif",
                              "jpeg",
                            ]}
                            maxFileSize={5242880}
                          />
                        )}
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                {formSubmitMessage.message ? (
                  <p
                    style={{
                      color:
                        formSubmitMessage.type == "success" ? "green" : "red",
                    }}
                  >
                    {formSubmitMessage.message}
                  </p>
                ) : null}
                <CardFooter>
                  <Button color="primary" type="submit">
                    Update Settings
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
      )}
    </div>
  );
}
