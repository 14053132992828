import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from "axios";
import { apiUrlRoot } from "../../config";
import verifyToken from "../../lib/verifyToken";

let token = localStorage.getItem("token");
const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [formSubmitMessage, setFormSubmitMessage] = useState({});
  const [formSubmitting, setFormSubmitting] = useState(false);

  useEffect(() => {
    if (token) {
      let isTokenValid = verifyToken(token);
      if (isTokenValid) {
        window.location.replace("/admin");
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, []);

  let handleChange = (e) => {
    let value = e.target.value;
    let newSettingsData = { ...form, [e.target.name]: value };
    setForm(newSettingsData);
  };

  let handleSubmit = async (e) => {
    setFormSubmitMessage({});
    setFormSubmitting(true);
    try {
      e.preventDefault();
      let body = form;
      let response = await axios.post(`${apiUrlRoot}/login`, body);
      if (response && response.data && response.data.status == 200) {
        setTokenToLocalStorage(response.data.data);
        window.location.replace("/login");
      } else {
        setFormSubmitMessage({
          message: "Error occured",
          type: "error",
        });
      }
      setFormSubmitting(false);
    } catch (err) {
      setFormSubmitMessage({
        message: err.response.data.message,
        type: "error",
      });
      setFormSubmitting(false);
    }
  };

  let setTokenToLocalStorage = (user) => {
    localStorage.setItem("token", user.token);
  };

  return (
    !loading && (
      <div>
        <form
          onSubmit={handleSubmit}
          style={{
            marginTop: "30vh",
          }}
        >
          <GridContainer
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Login</h4>
                  {/* <p className={classes.cardCategoryWhite}>Complete your profile</p> */}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "email",
                          value: form.email,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Password"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "password",
                          disabled: false,
                          name: "password",
                          value: form.password,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                {formSubmitMessage.message ? (
                  <p
                    style={{
                      color:
                        formSubmitMessage.type == "success" ? "green" : "red",
                    }}
                  >
                    {formSubmitMessage.message}
                  </p>
                ) : null}
                <CardFooter>
                  {formSubmitting ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    <Button color="primary" type="submit">
                      Login
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
      </div>
    )
  );
}
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  removeTextContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    background: "#d8d8d8",
    borderRadius: ".3rem",
    cursor: "pointer",
    "&:hover": {
      background: "#565555",
    },
  },
  removeText: {
    color: "white",
    fontSize: "2rem",
  },
};
