import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Navigation() {
  const [showMenu, setShowMenu] = useState(false);

  let toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav
      class="navbar navbar-expand-lg navbar-light fixed-top shadow-sm"
      id="mainNav"
    >
      <div class="container px-5">
        <Link class="navbar-brand fw-bold" to="/">
          Parambath
          <span
            style={{
              fontSize: "0.55rem",
              display: "flex",
              marginTop: "-.5rem",
              justifyContent: "flex-end",
            }}
          >
            Powered by Onnich
          </span>
        </Link>
        <button class="navbar-toggler" onClick={toggleMenu}>
          Menu
          <i class="bi-list"></i>
        </button>
        <div
          class="collapse navbar-collapse"
          id="navbarResponsive"
          style={{ display: showMenu ? "block" : "none" }}
        >
          <ul class="navbar-nav ms-auto me-4 my-3 my-lg-0">
            <li class="nav-item">
              <a
                class="nav-link me-lg-3"
                href="/#features"
                onClick={() => setShowMenu(false)}
              >
                Features
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link me-lg-3"
                href="/#download"
                onClick={() => setShowMenu(false)}
              >
                Download
              </a>
            </li>
          </ul>
          <button
            class="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0"
            data-bs-toggle="modal"
            onClick={() => setShowMenu(false)}
          >
            <span class="d-flex align-items-center">
              <a
                href="tel:+919946792650"
                style={{
                  color: "white",
                }}
              >
                <i class="bi-telephone-fill me-2"></i>
                <span class="small">+919946792650</span>
              </a>
            </span>
          </button>
        </div>
      </div>
    </nav>
  );
}
