import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from "axios";
import { apiUrl } from "../../config";
import { Dialog, DialogActions } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import ImageUploader from "react-images-upload";
import cloudinaryService from "../../lib/cloudinaryService";
import { Close } from "@material-ui/icons";
import Loader from "react-loader-spinner";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  removeTextContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    background: "#d8d8d8",
    borderRadius: ".3rem",
    cursor: "pointer",
    "&:hover": {
      background: "#565555",
    },
  },
  removeText: {
    color: "white",
    fontSize: "2rem",
  },
};

let token = localStorage.getItem("token");
const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();

  const [term, setTerms] = useState({
    _id: "",
    text: "",
  });
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formSubmitMessage, setFormSubmitMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [imageDropped, setImageDropped] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [indexOfImageToRemove, setIndexOfImageToRemove] = useState("");

  useEffect(() => {
    fetchTerms();
  }, []);

  let fetchTerms = async () => {
    try {
      let response = await axios.get(`${apiUrl}/term`, {
        headers: {
          "x-access-token": token,
        },
      });
      if (response && response.data && response.data.status == 200) {
        setTerms(response.data.data);
      } else {
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  let handleChange = (e) => {
    let value = e.target.value;
    let newTermsData = { ...term, [e.target.name]: value };
    setTerms(newTermsData);
  };

  let handleSubmit = async (e) => {
    setFormSubmitMessage({});
    setFormSubmitting(true);
    try {
      e.preventDefault();
      let body = term;
      let response = await axios.put(`${apiUrl}/term`, body, {
        headers: {
          "x-access-token": token,
        },
      });
      if (response && response.data && response.data.status == 200) {
        setFormSubmitMessage({
          message: "Terms updated successfully",
          type: "success",
        });
      } else {
        setFormSubmitMessage({
          message: "Error occured",
          type: "error",
        });
      }
      setFormSubmitting(false);
    } catch (err) {
      setFormSubmitMessage({
        message: err.response.data.message,
        type: "error",
      });
      setFormSubmitting(false);
    }
  };

  let handleOndrop = async (image) => {
    setImageDropped(true);
    let cloudinaryOptions = {
      directory: "term",
    };
    let cloudinaryResponse = await cloudinaryService(image, cloudinaryOptions);
    setTerms({
      ...term,
      images: [...term.images, cloudinaryResponse.body.secure_url],
    });
    setImageDropped(false);
  };

  let openDialog = (index) => {
    setIndexOfImageToRemove(index);
    setDialogStatus(true);
  };

  let closeDialog = () => {
    setIndexOfImageToRemove("");
    setDialogStatus(false);
  };

  let removeImage = () => {
    term.images.splice(indexOfImageToRemove, 1);
    setTerms({
      ...term,
      images: term.images,
    });
    setIndexOfImageToRemove("");
    setDialogStatus(false);
  };

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  return (
    <div>
      <Dialog
        open={dialogStatus}
        onClose={closeDialog}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            Do you want to remove the image?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={removeImage} color="primary">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <form onSubmit={handleSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Terms</h4>
                  {/* <p className={classes.cardCategoryWhite}>Complete your profile</p> */}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Terms & Conditions"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          rows: 20,
                          multiline: true,
                          disabled: false,
                          name: "text",
                          value: term.text,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                {formSubmitMessage.message ? (
                  <p
                    style={{
                      color:
                        formSubmitMessage.type == "success" ? "green" : "red",
                    }}
                  >
                    {formSubmitMessage.message}
                  </p>
                ) : null}
                <CardFooter>
                  <Button color="primary" type="submit">
                    Update Terms
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
      )}
    </div>
  );
}
