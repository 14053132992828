import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Modal } from "@material-ui/core";
import axios from "axios";
import { apiUrl } from "../../config";
import ImageUploader from "react-images-upload";
import cloudinaryService from "../../lib/cloudinaryService";
import Loader from "react-loader-spinner";
import { Close } from "@material-ui/icons";
import {
  Dialog,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  removeTextContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    background: "#d8d8d8",
    borderRadius: ".3rem",
    cursor: "pointer",
    "&:hover": {
      background: "#565555",
    },
  },
  removeText: {
    color: "white",
    fontSize: "2rem",
  },
};

let token = localStorage.getItem("token");
const useStyles = makeStyles(styles);

export default function AddNew(props) {
  let { modalOpen, setModalOpen } = props;
  let [formData, setFormData] = useState({
    _id: "",
    name: "",
    malayalamName: "",
    about: "",
    businessCategory: "",
    owner: "",
    ownerMalayalamName: "",
    phoneNumber: "",
    phoneNumber2: "",
    onlineBookingUrl: "",
    onlineDelivery: false,
    isPremium: false,
    show: true,
    images: [],
  });
  const [imageDropped, setImageDropped] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formSubmitMessage, setFormSubmitMessage] = useState({});
  const [dialogStatus, setDialogStatus] = useState(false);
  const [indexOfImageToRemove, setIndexOfImageToRemove] = useState("");
  const [businessCategories, setBusinessCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    fetchBusinessCategories();
    if (props.data) {
      setFormData({
        ...props.data,
        businessCategory: props.data.businessCategory._id,
      });
    }
  }, [props.data]);

  let fetchBusinessCategories = async () => {
    try {
      let response = await axios.get(`${apiUrl}/business-category/list?`, {
        headers: {
          "x-access-token": token,
        },
      });
      if (response && response.data && response.data.status == 200) {
        if (!props.data) {
          setFormData({
            ...formData,
            businessCategory: response.data.data[0]._id,
          });
        }
        setBusinessCategories(response.data.data);
      } else {
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  let handleChange = (e) => {
    let value =
      e.target.name == "isPremium" ||
      e.target.name == "show" ||
      e.target.name == "onlineDelivery" ||
      e.target.name == "upi" ||
      e.target.name == "card"
        ? e.target.checked
        : e.target.value;
    let newFormData = { ...formData, [e.target.name]: value };
    setFormData(newFormData);
  };

  let handleOndrop = async (image) => {
    setImageDropped(true);
    let cloudinaryOptions = {
      directory: "business",
    };
    let cloudinaryResponse = await cloudinaryService(image, cloudinaryOptions);
    setFormData({
      ...formData,
      images: [...formData.images, cloudinaryResponse.body.secure_url],
    });
    setImageDropped(false);
  };

  let openDialog = (index) => {
    setIndexOfImageToRemove(index);
    setDialogStatus(true);
  };

  let closeDialog = () => {
    setIndexOfImageToRemove("");
    setDialogStatus(false);
  };

  let removeImage = () => {
    formData.images.splice(indexOfImageToRemove, 1);
    setFormData({
      ...formData,
      images: formData.images,
    });
    setIndexOfImageToRemove("");
    setDialogStatus(false);
  };

  let handleSubmit = async (e) => {
    setFormSubmitMessage({});
    setFormSubmitting(true);
    try {
      e.preventDefault();
      let body = formData;
      if (props.data) {
        let response = await axios.put(
          `${apiUrl}/business/${formData._id}`,
          body,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        if (response && response.data && response.data.status == 200) {
          setFormSubmitMessage({
            message: "Business edited successfully",

            type: "success",
          });
          props.updateList(response.data.data);
        } else {
          setFormSubmitMessage({
            message: "Error occured",
            type: "error",
          });
        }
      } else {
        delete body._id;
        let response = await axios.post(`${apiUrl}/business`, body, {
          headers: {
            "x-access-token": token,
          },
        });
        if (response && response.data && response.data.status == 200) {
          setFormSubmitMessage({
            message: "Business added successfully",
            type: "success",
          });
          props.updateList(response.data.data);
        } else {
          setFormSubmitMessage({
            message: "Error occured",
            type: "error",
          });
        }
      }
      setFormSubmitting(false);
    } catch (err) {
      setFormSubmitMessage({
        message: err.response.data.message,
        type: "error",
      });
      setFormSubmitting(false);
    }
  };

  let closeModal = () => {
    setFormSubmitMessage({});
    setModalOpen(false);
    setFormData({});
    props.setItemToEdit("");
  };

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  return loading ? (
    <CircularProgress color="inherit" />
  ) : (
    <div>
      <Dialog
        open={dialogStatus}
        onClose={closeDialog}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            Do you want to remove the image?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={removeImage} color="primary">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          overflowY: "scroll",
          maxWidth: "100%",
          overflowX: "hidden",
          height: "100%",
          display: "block",
        }}
      >
        <form onSubmit={handleSubmit}>
          <GridContainer
            style={{
              justifyContent: "center",
            }}
          >
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4 className={classes.cardTitleWhite}>Add new</h4>
                  <Close
                    onClick={closeModal}
                    className={classes.closeIcon}
                  />{" "}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem
                      container
                      direction="row"
                      justify="left"
                      alignItems="left"
                    >
                      {formData.images &&
                        formData.images.length !== 0 &&
                        formData.images.map((image, index) => {
                          return (
                            <GridItem xs={6} sm={6} md={4} md={3}>
                              <div className={classes.imageContainer}>
                                <img src={image} className={classes.image} />
                                <div className={classes.removeTextContainer}>
                                  <div
                                    className={classes.removeText}
                                    onClick={() => openDialog(index)}
                                  >
                                    <Close />
                                  </div>
                                </div>
                              </div>
                            </GridItem>
                          );
                        })}
                      <GridItem xs={6} sm={6} md={4} md={3}>
                        {imageDropped ? (
                          <Loader
                            type="ThreeDots"
                            color="#87CB16"
                            height={30}
                            width={30}
                          />
                        ) : (
                          <ImageUploader
                            withIcon={true}
                            withLabel={false}
                            buttonText="Upload image"
                            onChange={handleOndrop}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".gif",
                              "jpeg",
                            ]}
                            maxFileSize={5242880}
                          />
                        )}
                      </GridItem>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl required style={{ width: "100%" }}>
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Category
                        </InputLabel>
                        <Select
                          autoWidth={true}
                          native
                          onChange={handleChange}
                          label="Category"
                          inputProps={{
                            disabled: false,
                            name: "businessCategory",
                            value: formData.businessCategory,
                          }}
                        >
                          {businessCategories.map((businessCategory) => {
                            return (
                              <option value={businessCategory._id}>
                                {businessCategory.name}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "name",
                          value: formData.name,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Malayalam Name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "malayalamName",
                          value: formData.malayalamName,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="About"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          multiline: true,
                          rows: 3,
                          disabled: false,
                          name: "about",
                          value: formData.about,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Owner"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "owner",
                          value: formData.owner,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Owner Malayalam Name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "ownerMalayalamName",
                          value: formData.ownerMalayalamName,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Phone number"
                        name="phoneNumber"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "phoneNumber",
                          value: formData.phoneNumber,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Phone number 2"
                        name="phoneNumber2"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "phoneNumber2",
                          value: formData.phoneNumber2,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Opens At"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "opensAt",
                          value: formData.opensAt,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Closes At"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "closesAt",
                          value: formData.closesAt,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Place"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "place",
                          value: formData.place,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "address",
                          value: formData.address,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "email",
                          value: formData.email,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Website"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "website",
                          value: formData.website,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Facebook"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "facebook",
                          value: formData.facebook,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Instagram"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "instagram",
                          value: formData.instagram,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Whatsapp"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "whatsapp",
                          value: formData.whatsapp,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Youtube"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "youtube",
                          value: formData.youtube,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Online Booking URL"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          name: "onlineBookingUrl",
                          value: formData.onlineBookingUrl,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.upi}
                            onChange={handleChange}
                            name="upi"
                            indeterminate
                          />
                        }
                        label="UPI"
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.card}
                            onChange={handleChange}
                            name="card"
                            indeterminate
                          />
                        }
                        label="Card"
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.onlineDelivery}
                            onChange={handleChange}
                            name="onlineDelivery"
                            indeterminate
                          />
                        }
                        label="Online Delivery"
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.isPremium}
                            onChange={handleChange}
                            name="isPremium"
                            indeterminate
                          />
                        }
                        label="Premium"
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.show}
                            onChange={handleChange}
                            name="show"
                            indeterminate
                          />
                        }
                        label="Show"
                      />
                    </GridItem>
                  </GridContainer>
                  {formSubmitMessage.message ? (
                    <p
                      style={{
                        color:
                          formSubmitMessage.type == "success" ? "green" : "red",
                      }}
                    >
                      {formSubmitMessage.message}
                    </p>
                  ) : null}
                </CardBody>
                <CardFooter>
                  {formSubmitting ? (
                    <CircularProgress color="inherit" />
                  ) : formSubmitMessage.type == "success" ? (
                    <Button color="default" onClick={closeModal}>
                      Close
                    </Button>
                  ) : (
                    <Button color="primary" type="submit">
                      Save
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
      </Modal>
    </div>
  );
}
