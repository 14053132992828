import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { CircularProgress } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Modal } from "@material-ui/core";
import axios from "axios";
import { apiUrl } from "../../config";
import ImageUploader from "react-images-upload";
import cloudinaryService from "../../lib/cloudinaryService";
import Loader from "react-loader-spinner";
import { Close } from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  removeTextContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    background: "#d8d8d8",
    borderRadius: ".3rem",
    cursor: "pointer",
    "&:hover": {
      background: "#565555",
    },
  },
  removeText: {
    color: "white",
    fontSize: "2rem",
  },
};

let token = localStorage.getItem("token");
const useStyles = makeStyles(styles);

let tabs = ["Home", "Event", "Delivery"];

let linkTypesHome = [
  "Business",
  "Auto",
  "Vehicle",
  "Worker",
  "Emergency",
  "Representative",
  "Enterprise",
  "OnlineService",
  // "Page",
];

export default function AddNew(props) {
  let { modalOpen, setModalOpen } = props;
  const [formData, setFormData] = useState({
    _id: "",
    tab: "",
    linkType: "",
    link: "",
    image: "",
  });
  const [imageDropped, setImageDropped] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formSubmitMessage, setFormSubmitMessage] = useState({});
  const [dialogStatus, setDialogStatus] = useState(false);
  const [linkTypes, setlinkTypes] = useState([]);

  const [links, setLinks] = useState([]);
  const [pageSelected, setPageSelected] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (props.data) {
      setFormData(props.data);
      handleChangeTab(props.data.tab);
      handleChangeLinkType(props.data.tab, props.data.linkType);
    } else {
      handleChangeTab("Home");
    }
  }, [props.data]);

  let handleChangeTab = (tab) => {
    setPageSelected(false);
    let newFormData = {
      ...formData,
      tab: tab,
      linkType: "",
      link: "",
    };
    setFormData(newFormData);
    let linkTypesVar = tab === "Home" ? linkTypesHome : [tab];
    setlinkTypes(linkTypesVar);
    handleChangeLinkType(tab, linkTypesVar[0]);
  };

  let handleChangeLinkType = (tab, linkType) => {
    if (linkType == "Page") {
      setPageSelected(true);
    } else {
      fetchLink(tab, linkType);
      setPageSelected(false);
    }
  };

  let fetchLink = async (tab, linkType) => {
    let linkTypeSlug =
      linkType === "Delivery"
        ? "business/delivery"
        : linkType === "Event"
        ? "event-category"
        : linkType === "OnlineService"
        ? "online-service"
        : linkType;

    let response = await axios.get(
      `${apiUrl}/${linkTypeSlug.toLowerCase()}/list?`,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    if (response && response.data && response.data.status == 200) {
      setLinks(response.data.data);
      let newFormData = {
        ...formData,
        tab: tab,
        linkType: linkType,
        link: response.data.data[0]._id,
      };
      setFormData(newFormData);
    } else {
    }
  };

  let handleChange = (e) => {
    let value =
      e.target.name == "isPremium" ? e.target.checked : e.target.value;
    let newFormData = { ...formData, [e.target.name]: value };
    setFormData(newFormData);
  };

  let handleOndrop = async (image) => {
    setImageDropped(true);
    let cloudinaryOptions = {
      directory: "carousel",
    };
    let cloudinaryResponse = await cloudinaryService(image, cloudinaryOptions);
    setFormData({
      ...formData,
      image: cloudinaryResponse.body.secure_url,
    });
    setImageDropped(false);
  };

  let openDialog = () => {
    setDialogStatus(true);
  };

  let closeDialog = () => {
    setDialogStatus(false);
  };

  let removeImage = () => {
    setFormData({
      ...formData,
      image: "",
    });
    setDialogStatus(false);
  };

  let handleSubmit = async (e) => {
    setFormSubmitMessage({});
    setFormSubmitting(true);
    try {
      e.preventDefault();
      let body = formData;
      delete body._id;
      if (props.data) {
        let response = await axios.put(
          `${apiUrl}/carousel/${props.data._id}`,
          body,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        if (response && response.data && response.data.status == 200) {
          setFormSubmitMessage({
            message: "Carousel edited successfully",
            type: "success",
          });
          props.updateList(response.data.data);
        } else {
          setFormSubmitMessage({
            message: "Error occured",
            type: "error",
          });
        }
      } else {
        let response = await axios.post(`${apiUrl}/carousel`, body, {
          headers: {
            "x-access-token": token,
          },
        });
        if (response && response.data && response.data.status == 200) {
          setFormSubmitMessage({
            message: "Carousel added successfully",
            type: "success",
          });
          props.updateList(response.data.data);
        } else {
          setFormSubmitMessage({
            message: "Error occured",
            type: "error",
          });
        }
      }

      setFormSubmitting(false);
    } catch (err) {
      setFormSubmitMessage({
        message: err.response.data.message,
        type: "error",
      });
      setFormSubmitting(false);
    }
  };

  let closeModal = () => {
    setFormData({
      _id: "",
      name: "",
      image: "",
    });
    setFormSubmitMessage({});
    setModalOpen(false);
  };

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  return (
    <div>
      <Dialog
        open={dialogStatus}
        onClose={closeDialog}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            Do you want to remove the image?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={removeImage} color="primary">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          overflowY: "scroll",
          maxWidth: "100%",
          overflowX: "hidden",
          height: "100%",
          display: "block",
        }}
      >
        <form onSubmit={handleSubmit}>
          <GridContainer
            style={{
              justifyContent: "center",
            }}
          >
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4 className={classes.cardTitleWhite}>Add new</h4>
                  <Close
                    onClick={() => setModalOpen(false)}
                    className={classes.closeIcon}
                  />
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem
                      container
                      direction="row"
                      justify="left"
                      alignItems="left"
                    >
                      {formData.image ? (
                        <GridItem xs={6} sm={6} md={4} md={3}>
                          <div className={classes.imageContainer}>
                            <img
                              src={formData.image}
                              className={classes.image}
                            />
                            <div className={classes.removeTextContainer}>
                              <div
                                className={classes.removeText}
                                onClick={openDialog}
                              >
                                <Close />
                              </div>
                            </div>
                          </div>
                        </GridItem>
                      ) : (
                        <GridItem xs={6} sm={6} md={4} md={3}>
                          {imageDropped ? (
                            <Loader
                              type="ThreeDots"
                              color="#87CB16"
                              height={30}
                              width={30}
                            />
                          ) : (
                            <ImageUploader
                              withIcon={true}
                              withLabel={false}
                              buttonText="Upload image"
                              onChange={handleOndrop}
                              imgExtension={[
                                ".jpg",
                                ".gif",
                                ".png",
                                ".gif",
                                "jpeg",
                              ]}
                              maxFileSize={5242880}
                            />
                          )}
                        </GridItem>
                      )}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl required style={{ width: "100%" }}>
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Tab
                        </InputLabel>
                        <Select
                          autoWidth={true}
                          native
                          onChange={(e) => handleChangeTab(e.target.value)}
                          label="Tab"
                          inputProps={{
                            disabled: false,
                            name: "tab",
                            value: formData.tab,
                          }}
                        >
                          {tabs.map((tab) => {
                            return <option value={tab}>{tab}</option>;
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl required style={{ width: "100%" }}>
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Link type
                        </InputLabel>
                        <Select
                          autoWidth={true}
                          native
                          onChange={(e) =>
                            handleChangeLinkType(formData.tab, e.target.value)
                          }
                          label="Link type"
                          inputProps={{
                            disabled: false,
                            name: "linkType",
                            value: formData.linkType,
                          }}
                        >
                          {linkTypes.map((linkType) => {
                            return <option value={linkType}>{linkType}</option>;
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>

                    {pageSelected ? (
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Link"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            name: "link",
                            value: formData.link,
                            onChange: handleChange,
                          }}
                        />
                      </GridItem>
                    ) : (
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl required style={{ width: "100%" }}>
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Link
                          </InputLabel>
                          <Select
                            autoWidth={true}
                            native
                            onChange={handleChange}
                            label="Link"
                            inputProps={{
                              disabled: false,
                              name: "link",
                              value: formData.link,
                            }}
                          >
                            {links.map((link) => {
                              return (
                                <option value={link._id}>
                                  {link.name ? link.name : link.title}
                                </option>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </GridItem>
                    )}
                  </GridContainer>
                  {formSubmitMessage.message ? (
                    <p
                      style={{
                        color:
                          formSubmitMessage.type == "success" ? "green" : "red",
                      }}
                    >
                      {formSubmitMessage.message}
                    </p>
                  ) : null}
                </CardBody>
                <CardFooter>
                  {formSubmitting ? (
                    <CircularProgress color="inherit" />
                  ) : formSubmitMessage.type == "success" ? (
                    <Button color="default" onClick={closeModal}>
                      Close
                    </Button>
                  ) : (
                    <Button color="primary" type="submit">
                      Save
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
      </Modal>
    </div>
  );
}
