import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { apiUrlRoot } from "../../config";
import { CircularProgress } from "@material-ui/core";
import Navigation from "components/Navbars/Navigation";

const gfm = require("remark-gfm");

export default function PrivacyPolicy() {
  const [terms, setTerms] = useState({ term: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTermsAndConditions();
  }, []);

  let fetchTermsAndConditions = async () => {
    try {
      setLoading(true);
      let response = await axios.get(`${apiUrlRoot}/customer/term`, {});
      if (response && response.data && response.data.status == 200) {
        setTerms(response.data.data);
      } else {
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "40vh",
      }}
    >
      <CircularProgress color="inherit" />
    </div>
  ) : (
    <div>
      <Navigation />
      <header class="masthead">
        <div class="container px-5">
          <div class="row gx-5 align-items-center">
            <div class="mb-5 mb-lg-0 text-center text-lg-start">
              <p class="lead fw-normal text-muted mb-5">
                <ReactMarkdown remarkPlugins={[gfm]} children={terms.text} />
              </p>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
