import React, { useState, useEffect } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import {
  Commute as CommuteIcon,
  Room as RoomIcon,
  DirectionsBus as DirectionsBusIcon,
  DirectionsWalk as DirectionsWalkIcon,
  Business as BusinessIcon,
  EmojiObjects as EmojiObjectIcon,
  ViewCarousel as ViewCarouselIcon,
  Event as EventIcon,
  Schedule as ScheduleIcon,
  Http as HttpIcon,
  SportsEsports as SportsEsportsIcon,
  Kitchen as KitchenIcon,
  Computer as ComputerIcon,
  LiveTv as LiveTvIcon,
  Help as HelpIcon,
  Assignment as AssignmentIcon,
  Person as PersonIcon,
  Category as CategoryIcon,
  Store as StoreIcon,
  Accessibility as AccessibilityIcon,
  LocalShipping as LocalShippingIcon,
} from "@material-ui/icons";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import { CircularProgress } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import axios from "axios";
import { apiUrl } from "../../config";
import { Link } from "react-router-dom";

let token = localStorage.getItem("token");
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const [stat, setStat] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDashboard();
  }, []);

  let fetchDashboard = async () => {
    try {
      setLoading(true);
      let response = await axios.get(`${apiUrl}/dashboard/stat`, {
        headers: {
          "x-access-token": token,
        },
      });
      if (response && response.data && response.data.status == 200) {
        setStat(response.data.data);
      } else {
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const classes = useStyles();
  return loading ? (
    <CircularProgress color="inherit" />
  ) : (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/businesses">
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <BusinessIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Businesses</p>
                <h3 className={classes.cardTitle}>{stat.businesses}</h3>
              </CardHeader>
            </Link>
            <Link to="/admin/business-category">
              <CardFooter stats>
                <div className={classes.stats}>
                  <BusinessIcon />
                  {stat.businessCategories} Categories
                </div>
              </CardFooter>
            </Link>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/enterprises">
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <CommuteIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Auto Rikshaws</p>
                <h3 className={classes.cardTitle}>{stat.autos}</h3>
              </CardHeader>
            </Link>
            <Link to="/admin/enterprise-category">
              <CardFooter stats>
                <div className={classes.stats}>
                  <CategoryIcon />
                  {stat.autoStands} Auto Stands
                </div>
              </CardFooter>
            </Link>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/bus-timing">
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <DirectionsBusIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Bus Timings</p>
                <h3 className={classes.cardTitle}>{stat.busTimings}</h3>
              </CardHeader>
            </Link>
            <Link to="/admin/bus-route">
              <CardFooter stats>
                <div className={classes.stats}>
                  <CategoryIcon />
                  {stat.busRoutes} Bus Routes
                </div>
              </CardFooter>
            </Link>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/workers">
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <DirectionsWalkIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Workers</p>
                <h3 className={classes.cardTitle}>{stat.workers}</h3>
              </CardHeader>
            </Link>
            <Link to="/admin/work-category">
              <CardFooter stats>
                <div className={classes.stats}>
                  <CategoryIcon />
                  {stat.workCategories} Categories
                </div>
              </CardFooter>
            </Link>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/emergencies">
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <EmojiObjectIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Emergencies</p>
                <h3 className={classes.cardTitle}>{stat.emergencies}</h3>
              </CardHeader>
            </Link>
            <Link to="/admin/emergency-category">
              <CardFooter stats>
                <div className={classes.stats}>
                  <CategoryIcon />
                  {stat.emergencyCategories} Categories
                </div>
              </CardFooter>
            </Link>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/vehicles">
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <LocalShippingIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Vehicles</p>
                <h3 className={classes.cardTitle}>{stat.vehicles}</h3>
              </CardHeader>
            </Link>
            <Link to="/admin/vehicle-category">
              <CardFooter stats>
                <div className={classes.stats}>
                  <CategoryIcon />
                  {stat.vehicleCategories} Categories
                </div>
              </CardFooter>
            </Link>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/representatives">
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <PersonIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Representatives</p>
                <h3 className={classes.cardTitle}>{stat.representatives}</h3>
              </CardHeader>
            </Link>
            <Link to="/admin/representative-categories">
              <CardFooter stats>
                <div className={classes.stats}>
                  <CategoryIcon />
                  {stat.representativeCategories} Categories
                </div>
              </CardFooter>
            </Link>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/events">
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <EventIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Events</p>
                <h3 className={classes.cardTitle}>{stat.events}</h3>
              </CardHeader>
            </Link>
            <Link to="/admin/event-categories">
              <CardFooter stats>
                <div className={classes.stats}>
                  <CategoryIcon />
                  {stat.eventCategories} Categories | {stat.eventSchedules}
                  Schedules
                </div>
              </CardFooter>
            </Link>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/online-services">
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <HttpIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Online Services</p>
                <h3 className={classes.cardTitle}>{stat.onlineServices}</h3>
              </CardHeader>
            </Link>
            <Link to="/admin/online-service-categories">
              <CardFooter stats>
                <div className={classes.stats}>
                  <CategoryIcon />
                  {stat.onlineServiceCategories} Categories
                </div>
              </CardFooter>
            </Link>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/offers">
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <HttpIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Offers</p>
                <h3 className={classes.cardTitle}>{stat.offers}</h3>
              </CardHeader>
            </Link>
            <Link to="/admin/offer-categories">
              <CardFooter stats>
                <div className={classes.stats}>
                  <CategoryIcon />
                  {stat.offerCategories} Categories
                </div>
              </CardFooter>
            </Link>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/channels">
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <LiveTvIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Channels</p>
                <h3 className={classes.cardTitle}>{stat.channels}</h3>
              </CardHeader>
            </Link>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/tools">
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ComputerIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Tools</p>
                <h3 className={classes.cardTitle}>{stat.tools}</h3>
              </CardHeader>
            </Link>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/cookings">
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <KitchenIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Cookings</p>
                <h3 className={classes.cardTitle}>{stat.cooking}</h3>
              </CardHeader>
            </Link>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/games">
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <SportsEsportsIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Games</p>
                <h3 className={classes.cardTitle}>{stat.games}</h3>
              </CardHeader>
            </Link>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <Link to="/admin/help">
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <HelpIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Help</p>
                <h3 className={classes.cardTitle}>{stat.help}</h3>
              </CardHeader>
            </Link>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
