import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Modal } from "@material-ui/core";
import axios from "axios";
import { apiUrl } from "../../config";
import ImageUploader from "react-images-upload";
import cloudinaryService from "../../lib/cloudinaryService";
import Loader from "react-loader-spinner";
import { Close } from "@material-ui/icons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";
import Draggable from "react-draggable";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  removeTextContainer: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    background: "#d8d8d8",
    borderRadius: ".3rem",
    cursor: "pointer",
    "&:hover": {
      background: "#565555",
    },
  },
  removeText: {
    color: "white",
    fontSize: "2rem",
  },
  daySheduleItem: {
    marginTop: "1rem",
    position: "relative",
    padding: "1rem",
    boxShadow: "0 2px 5px 0 #cac4c6, 0 8px 40px 0 rgb(10 14 29 / 6%)",
  },
};

let token = localStorage.getItem("token");
const useStyles = makeStyles(styles);

export default function AddNew(props) {
  let { modalOpen, setModalOpen } = props;
  let [formData, setFormData] = useState({
    _id: "",
    event: "",
    shedule: [
      {
        dayShedule: [
          {
            time: "",
            title: "",
            description: "",
          },
        ],
      },
    ],
  });
  const [imageDropped, setImageDropped] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formSubmitMessage, setFormSubmitMessage] = useState({});
  const [dialogStatus, setDialogStatus] = useState(false);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    fetchEventCategories();
    if (props.data) {
      setFormData({
        ...props.data,
        event: props.data.event._id,
      });
    }
  }, [props.data]);

  let fetchEventCategories = async () => {
    try {
      let response = await axios.get(`${apiUrl}/event/list?`, {
        headers: {
          "x-access-token": token,
        },
      });
      if (response && response.data && response.data.status == 200) {
        if (!props.data) {
          setFormData({
            ...formData,
            event: response.data.data[0]._id,
          });
        }
        setEvents(response.data.data);
      } else {
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  let handleChange = (e) => {
    let value =
      e.target.name == "isPremium" ? e.target.checked : e.target.value;
    let newFormData = { ...formData, [e.target.name]: value };
    setFormData(newFormData);
  };

  let handleOndrop = async (image) => {
    setImageDropped(true);
    let cloudinaryOptions = {
      directory: "eventShedule",
    };
    let cloudinaryResponse = await cloudinaryService(image, cloudinaryOptions);
    setFormData({
      ...formData,
      image: cloudinaryResponse.body.secure_url,
    });
    setImageDropped(false);
  };

  let openDialog = (index) => {
    setDialogStatus(true);
  };

  let closeDialog = () => {
    setDialogStatus(false);
  };

  let removeImage = () => {
    setFormData({
      ...formData,
      image: "",
    });
    setDialogStatus(false);
  };

  let handleSubmit = async (e) => {
    setFormSubmitMessage({});
    setFormSubmitting(true);
    try {
      e.preventDefault();
      let body = formData;
      if (props.data) {
        let response = await axios.put(
          `${apiUrl}/event-shedule/${formData._id}`,
          body,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        if (response && response.data && response.data.status == 200) {
          setFormSubmitMessage({
            message: "Event edited successfully",

            type: "success",
          });
          props.updateList(response.data.data);
        } else {
          setFormSubmitMessage({
            message: "Error occured",
            type: "error",
          });
        }
      } else {
        delete body._id;
        let response = await axios.post(`${apiUrl}/event-shedule`, body, {
          headers: {
            "x-access-token": token,
          },
        });

        if (response && response.data && response.data.status == 200) {
          setFormSubmitMessage({
            message: "Event Shedule added successfully",
            type: "success",
          });
          props.updateList(response.data.data);
        } else {
          setFormSubmitMessage({
            message: "Error occured",
            type: "error",
          });
        }
      }
      setFormSubmitting(false);
    } catch (err) {
      setFormSubmitMessage({
        message: err.response.data.message,
        type: "error",
      });
      setFormSubmitting(false);
    }
  };

  let closeModal = () => {
    setFormSubmitMessage({});
    setModalOpen(false);
    setFormData({
      shedule: [],
    });
    props.setItemToEdit("");
  };

  let handleChangeSheduleItem = (e, index) => {
    let name = e.target.name;
    let value = e.target.value;
    formData.shedule[index][name] = value;
    setFormData({
      ...formData,
      shedule: formData.shedule,
    });
  };

  let handleChangeDaySheduleItem = (
    e,
    sheduleArrayIndex,
    daySheduleArrayIndex
  ) => {
    let name = e.target.name;
    let value = e.target.value;
    formData.shedule[sheduleArrayIndex].dayShedule[daySheduleArrayIndex] = {
      ...formData.shedule[sheduleArrayIndex].dayShedule[daySheduleArrayIndex],
      [name]: value,
    };
    setFormData({
      ...formData,
      shedule: formData.shedule,
    });
  };

  let addNewSheduleItem = () => {
    formData.shedule.push({
      day: "",
      title: "",
      dayShedule: [
        {
          time: "",
          title: "",
          description: "",
        },
      ],
    });
    setFormData({
      ...formData,
      shedule: formData.shedule,
    });
  };

  let addNewDaySheduleItem = (sheduleArrayIndex) => {
    formData.shedule[sheduleArrayIndex].dayShedule.push({
      time: "",
      title: "",
      description: "",
    });
    setFormData({
      ...formData,
      shedule: formData.shedule,
    });
  };

  let removeDaySheduleItem = (index) => {
    formData.shedule.splice(index, 1);
    setFormData({
      ...formData,
      shedule: formData.shedule,
    });
  };

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  return loading ? (
    <CircularProgress color="inherit" />
  ) : (
    <div>
      <Dialog
        open={dialogStatus}
        onClose={closeDialog}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            Do you want to remove the image?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={removeImage} color="primary">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          overflowY: "scroll",
          maxWidth: "100%",
          overflowX: "hidden",
          height: "100%",
          display: "block",
        }}
      >
        <form onSubmit={handleSubmit}>
          <GridContainer
            style={{
              justifyContent: "center",
            }}
          >
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4 className={classes.cardTitleWhite}>Add new</h4>
                  <Close
                    onClick={closeModal}
                    className={classes.closeIcon}
                  />{" "}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl required style={{ width: "100%" }}>
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Event
                        </InputLabel>
                        <Select
                          autoWidth={true}
                          native
                          onChange={handleChange}
                          label="Event"
                          inputProps={{
                            disabled: false,
                            name: "event",
                            value: formData.event,
                          }}
                        >
                          {events.map((event) => {
                            return (
                              <option value={event._id}>{event.name}</option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <h4>Shedule</h4>
                    </GridItem>
                    {formData.shedule.map((shedule, sheduleArrayIndex) => {
                      return (
                        <Fragment>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Day"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                disabled: false,
                                name: "day",
                                value: shedule.day,
                                onChange: (e) =>
                                  handleChangeSheduleItem(e, sheduleArrayIndex),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Title"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                disabled: false,
                                name: "title",
                                value: shedule.title,
                                onChange: (e) =>
                                  handleChangeSheduleItem(e, sheduleArrayIndex),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <h4>Shedule of the day</h4>
                          </GridItem>
                          {shedule.dayShedule.map(
                            (dayShedule, daySheduleArrayIndex) => {
                              return (
                                <GridItem xs={12} sm={12} md={6}>
                                  <div className={classes.daySheduleItem}>
                                    <div
                                      className={classes.removeTextContainer}
                                    >
                                      <div
                                        className={classes.removeText}
                                        onClick={() =>
                                          removeDaySheduleItem(
                                            sheduleArrayIndex,
                                            daySheduleArrayIndex
                                          )
                                        }
                                      >
                                        <Close />
                                      </div>
                                    </div>
                                    <CustomInput
                                      labelText="Time"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        disabled: false,
                                        name: "time",
                                        value: dayShedule.time,
                                        onChange: (e) =>
                                          handleChangeDaySheduleItem(
                                            e,
                                            sheduleArrayIndex,
                                            daySheduleArrayIndex
                                          ),
                                      }}
                                    />
                                    <CustomInput
                                      labelText="Title"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        disabled: false,
                                        name: "title",
                                        value: dayShedule.title,
                                        onChange: (e) =>
                                          handleChangeDaySheduleItem(
                                            e,
                                            sheduleArrayIndex,
                                            daySheduleArrayIndex
                                          ),
                                      }}
                                    />
                                    <CustomInput
                                      labelText="Description"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        disabled: false,
                                        name: "description",
                                        value: dayShedule.description,
                                        onChange: (e) =>
                                          handleChangeDaySheduleItem(
                                            e,
                                            sheduleArrayIndex,
                                            daySheduleArrayIndex
                                          ),
                                      }}
                                    />
                                  </div>
                                </GridItem>
                              );
                            }
                          )}
                          <GridItem xs={12} sm={12} md={12}>
                            <Button
                              color="white"
                              onClick={() =>
                                addNewDaySheduleItem(sheduleArrayIndex)
                              }
                            >
                              Add New Day Shedule item
                            </Button>
                          </GridItem>
                        </Fragment>
                      );
                    })}

                    <Button color="default" onClick={() => addNewSheduleItem()}>
                      Add New Shedule item
                    </Button>
                  </GridContainer>
                  {formSubmitMessage.message ? (
                    <p
                      style={{
                        color:
                          formSubmitMessage.type == "success" ? "green" : "red",
                      }}
                    >
                      {formSubmitMessage.message}
                    </p>
                  ) : null}
                </CardBody>
                <CardFooter>
                  {formSubmitting ? (
                    <CircularProgress color="inherit" />
                  ) : formSubmitMessage.type == "success" ? (
                    <Button color="default" onClick={closeModal}>
                      Close
                    </Button>
                  ) : (
                    <Button color="primary" type="submit">
                      Save
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
      </Modal>
    </div>
  );
}
